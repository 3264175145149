<template>
  <div>
    <h2 class="my-4 text-uppercase">Current Promotions</h2>
    <v-row class="mb-5">
      <v-col v-for="(promotion, i) in currentPromotions" :key="i" cols="4">
        <v-card elevation="8" outlined>
          <v-img :src="promotion.img" height="200px">
            <v-app-bar flat color="transparent">
              <v-spacer />
              <v-btn icon color="white">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-app-bar>
          </v-img>
          <v-card-title>{{ promotion.title }}</v-card-title>
          <v-card-subtitle>{{ promotion.subtitle }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <h2 class="my-4 text-uppercase">Choose Promotion</h2>
    <v-row>
      <v-col v-for="(promotion, i) in allPromotions" :key="i" cols="4">
        <v-card elevation="8" outlined>
          <v-card-title>{{ promotion.title }}</v-card-title>
          <v-card-subtitle>{{ promotion.subtitle }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "promotions",
  data() {
    return {
      currentPromotions: [
        {
          title: "Buy One Get One Free",
          subtitle: "Spend $20, get 1 free Caesar Wrap",
          img: "https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Nnx8fGVufDB8fHx8&w=1000&q=80",
        },
      ],
      allPromotions: [
        {
          title: "Free Item with Purchase",
          subtitle:
            "Delight customers by offering 1 free item when they spend more.",
        },
        {
          title: "CA $0 Delivery Fee",
          subtitle: "Cover your customer's delivery fee up to a certain amount.",
        },
        {
          title: "Stories",
          subtitle: "Post stories on your page so customers know what you're up to.",
        },
        {
          title: "Buy 1, Get 1 Free",
          subtitle: "Set BOGO discounts on up to 5 items.",
        },
        {
          title: "Spend More, Save More",
          subtitle:
            "Set a minimum spend and offer customers savings on their order.",
        },
        {
          title: "Save on Menu Items",
          subtitle: "Set a percentage off selec items or a menu category.",
        },
      ],
    }
  },
}
</script>
